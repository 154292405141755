<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Редактировать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Название" prop="title">
        <el-input v-model="form.title" maxlength="100"></el-input>
      </el-form-item>

      <el-form-item label="Описание" prop="description">
        <TextEditor
          :value.sync="form.description"
          :is-image="false"
          :is-media="false"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import TextEditor from '@/components/atoms/TextEditor.vue'
import notifications from '@/mixins/notifications'

export default {
  components: { TextEditor },
  mixins: [notifications],
  data() {
    return {
      form: {
        title: '',
        description: '',
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },
  async created() {
    await this.getSeoText()
  },
  methods: {
    async getSeoText() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.SeoTextsActions.getByIds(
          this.$route.params.id,
        )

      if (error) return
      this.form = { ...value[0] }

      loading.close()
    },

    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const result =
            await delivery.ContentServiceCore.SeoTextsActions.update(
              this.$route.params.id,
              this.form,
            )
          loading.close()
          if (!result.error) {
            this.showNotification('Seo текст успешно отредактирован', 'success')
            this.$router.push('/addwine/content/seo-texts')
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .form {
    & > div > label {
      text-align: left;
    }

    .date {
      width: 100%;
    }
  }
}
</style>
